.logo {
    height: 100px;
}
.language-selection {
    padding: 25px 0;
}
.intro-header {
    background: #004494;
    color: #fff;
    margin: 0 0 6rem;
    padding: 1rem 0 1rem;
    position: relative;
}
.intro-header:after {
    content: '';
    background: #4a90e2;
    height: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}
.subtitle {
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 2rem;
    font-size: 1.4rem;
}
#footer-extra {
    background: #F5F5F5;
    margin: 5rem 0 0;
    padding: 1.5rem 0;
}
#footer-extra a {
    color: #26527D;
}

#footer-links {
    background: #004494;
    padding: 3rem 0;
}
#footer-links ul {
    padding: 0;
}
#footer-links ul li {
    display: inline-block;
    margin-right: 4rem;
    margin-bottom: 0;
}
#footer-links a {
    color: #fff;
    padding: 0 25px 0 0;
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
}

@media (max-width: 767px) {
    #footer-links a {
        display: block;
    }
}
