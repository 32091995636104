.language-info {
    background-color: #eeeeee;
    border-radius: 5px;
    margin: 0 0 3rem;
    padding: 1.5rem 1rem !important;
}

.ui.styled.accordion {
    width: 100% !important;
}

.first-column {
    border-right: 1px solid #e4e4e4;
    padding-right: 50px !important;
}
.second-column {
    padding-left: 50px !important;
}
.second-column .ui.styled.accordion {
    box-shadow: none;
}
.second-column .ui.styled.accordion .title {
    background: #f6f6f6 !important;
    border: 0;
    color: #000;
}
.expert-details {
    background: #fcfcfc;
    padding: 2rem !important;
}
.expert-details span {
    font-weight: 700;
}
.expert-details .ui.circular.button {
    background: #004494;
    color: #fff;
}
.ui.labeled.button.download-btn:not(.icon) {
    background: #fff !important;
    border: 2px solid #383838 !important;
    color: #383838 !important;
    transition: all .4s ease;
    padding: 10px 20px !important;
    margin-bottom: 15px;
}
.ui.labeled.button.download-btn:not(.icon):hover {
    background: #fff !important;
    border: 2px solid #0057BD !important;
    color: #0057BD !important;
    transition: all .4s ease;
}
.ui input, .ui.selection {
    border: 1px solid #292929 !important;
}

.ui.search .input {
    width: 100%;
}

.ui .field label {
    margin-bottom: 5px !important;
}
.ui.primary.button, .ui.primary.buttons .button {
    background: #004494 !important;
    box-shadow: none !important;
    border-radius: 1px;
    border: 0 !important;
    padding: 15px 20px;
    font-size: 16px;
}
/* .reset-btn {
    background: #eee !important;
    border: 1px solid #ddd !important;
    border-radius: 1px !important;
    padding: 15px !important;
    margin-left: 5px !important;
} */

label {
    color: dimgrey;
    font-weight: 700;
}

.ui.pagination {
    border: 0 !important;
}
.ui.pagination .item {
    border: 1px solid #eee !important;
    margin: 0 3px !important;
    transition: all .4s ease !important;
}
.ui.pagination .active.item,
.ui.pagination .item:hover {
    border-color: #292929 !important;
    transition: all .4s ease !important;
}
